import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import axios from "axios"
import clsx from "clsx"
import parse from "html-react-parser"
import hljs from "highlight.js/lib"
import "highlight.js/styles/monokai-sublime.css"
import "@wordpress/block-library/build-style/style.css"
import { BlogLayout, Head, Button } from "~/components"
import {
  IconCalendar,
  IconHome,
  IconArrowRight,
  IconArrowLeft,
  IconError,
  IconLine,
  IconHatena,
  IconFacebook,
  IconTwitter,
  IconMessages,
  IconMessage,
} from "~/icons"
import {
  FacebookShareButton,
  FacebookIcon,
  HatenaShareButton,
  HatenaIcon,
  LineShareButton,
  LineIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import { ascOrder } from "~/utils"
import * as styles from "~/styles/blog.module.scss"

const BlogPostTemplate = ({
  data: { previous, next, post, allPageViews, allWpPost },
  location,
}) => {
  const { handleSubmit, register, errors, reset } = useForm()
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const popularPost = allWpPost.nodes
    .filter(node => {
      return allPageViews.nodes.some(page => page.path === `/blog${node.uri}`)
    })
    .map(node => {
      const popular = allPageViews.nodes.filter(
        page => page.path === `/blog${node.uri}`
      )
      return { ...node, totalCount: popular[0].totalCount }
    })
    .sort((a, b) => (a.totalCount > b.totalCount ? -1 : 1))

  useEffect(() => {
    hljs.initHighlighting()
    hljs.initHighlighting.called = false
  })

  async function postComment(data) {
    const today = new Date()
    const bodyFormData = new FormData()

    bodyFormData.set("author_email", data.author_email)
    bodyFormData.set("author_name", data.author_name)
    bodyFormData.set("author_url", data.author_url)
    bodyFormData.set(
      "author_user_agent",
      window.navigator.userAgent.toLowerCase()
    )
    bodyFormData.set("content", data.content)
    bodyFormData.set("date", today.toISOString())
    bodyFormData.set("parent", Number(data.comment_parent))
    bodyFormData.set("post", Number(data.comment_post_ID))

    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_COMMENT_URL}/wp-json/wp/v2/comments`,
        data: bodyFormData,
        headers: {
          "Content-Type": "application/json",
        },
      })
      reset()
    } catch (error) {}
  }

  return (
    <BlogLayout>
      <Head title={post.title} description={post.excerpt} />
      <div className={styles.breadcrumb}>
        <ul className={styles.breadcrumb__list}>
          <li className={styles.breadcrumb__item}>
            <IconHome />
            <Link to="/blog/" className={styles.breadcrumb__link}>
              ホーム
            </Link>
          </li>
          <li className={styles.breadcrumb__item}>
            <Link to="/blog/" className={styles.breadcrumb__link}>
              テクノロジー
            </Link>
          </li>
          <li className={styles.breadcrumb__item}>{parse(post.title)}</li>
        </ul>
      </div>

      <div className={styles.contents}>
        <main className={styles.postMain}>
          <article
            className={styles.article}
            itemScope
            itemType="http://schema.org/Article"
          >
            {featuredImage?.fluid ? (
              <Img
                fluid={featuredImage.fluid}
                alt={featuredImage.alt}
                className={styles.article__img}
              />
            ) : (
              <StaticImage
                src="../images/common/noimg.png"
                alt=""
                width={760}
                className={styles.article__img}
              />
            )}
            <header>
              <div className={styles.article__date}>
                <IconCalendar />
                <time>{post.date}</time>
              </div>
              <h1 className={styles.article__title} itemProp="headline">
                {parse(post.title)}
              </h1>
            </header>
            {!!post.content && (
              <section className={styles.article__body} itemProp="articleBody">
                {parse(post.content)}
              </section>
            )}
            <footer></footer>
          </article>

          {!!post.commentCount && (
            <section className={styles.section}>
              <h2 className={styles.heading}>
                <IconMessages />
                <span>{post.commentCount}</span>個のコメント
              </h2>
              {post.comments.nodes.sort(ascOrder).map(comment => (
                <article
                  key={comment.id}
                  id={comment.id}
                  className={styles.comment}
                >
                  <header className={styles.commentHeader}>
                    <h3 className={styles.commentTitle}>
                      <span className={styles.commentAuthor}>
                        <strong>{comment.author.node.name}</strong>より
                      </span>
                      {comment.parentId && (
                        <span className={styles.commentAuthor}>
                          <strong>
                            <a
                              href={`#${
                                post.comments.nodes.filter(
                                  parent => parent.id === comment.parentId
                                )[0].id
                              }`}
                            >
                              {
                                post.comments.nodes.filter(
                                  parent => parent.id === comment.parentId
                                )[0].author.node.name
                              }
                            </a>
                          </strong>
                          に返信
                        </span>
                      )}
                      ：
                    </h3>
                    <a href={`#${comment.id}`} className={styles.commentDate}>
                      {comment.date}
                    </a>
                  </header>
                  <div className={styles.commentBody}>
                    {parse(comment.content)}
                  </div>
                </article>
              ))}
            </section>
          )}

          <section className={styles.section}>
            <h2 className={styles.heading}>
              <IconMessage />
              コメントを残す
            </h2>
            <form className={styles.form} onSubmit={handleSubmit(postComment)}>
              <p className={styles.formText}>
                メールアドレスが公開されることはありません。*がついている欄は必須項目です。
              </p>
              <label className={styles.field}>
                <div className={styles.fieldLabel}>
                  コメント<span>*</span>
                </div>
                <textarea
                  rows="8"
                  cols="45"
                  name="content"
                  className={clsx(
                    "textarea-primary",
                    styles.textarea,
                    errors.content && "is-error"
                  )}
                  ref={register({
                    required: "コメントは必須項目です。",
                  })}
                />
                <ErrorMessage
                  name="content"
                  errors={errors}
                  render={({ message }) => (
                    <p className={styles.fieldAlert}>
                      <IconError className={styles.fieldIcon} />
                      {message}
                    </p>
                  )}
                />
              </label>
              <label className={styles.inputField}>
                <div className={styles.fieldLabel}>
                  名前<span>*</span>
                </div>
                <input
                  type="text"
                  name="author_name"
                  className={clsx(
                    "input-primary",
                    styles.input,
                    errors.author_name && "is-error"
                  )}
                  ref={register({
                    required: "名前は必須項目です。",
                  })}
                />
                <ErrorMessage
                  name="author_name"
                  errors={errors}
                  render={({ message }) => (
                    <p className={styles.fieldAlert}>
                      <IconError className={styles.fieldIcon} />
                      {message}
                    </p>
                  )}
                />
              </label>
              <label className={styles.inputField}>
                <div className={styles.fieldLabel}>
                  メールアドレス<span>*</span>
                </div>
                <input
                  type="text"
                  name="author_email"
                  className={clsx(
                    "input-primary",
                    styles.input,
                    errors.author_email && "is-error"
                  )}
                  ref={register({
                    required: "メールアドレスは必須項目です。",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message:
                        "メールアドレス形式に誤りがあります。正しい内容を入力してください。",
                    },
                  })}
                />
                <ErrorMessage
                  name="author_email"
                  errors={errors}
                  render={({ message }) => (
                    <p className={styles.fieldAlert}>
                      <IconError className={styles.fieldIcon} />
                      {message}
                    </p>
                  )}
                />
              </label>
              <label className={styles.inputField}>
                <div className={styles.fieldLabel}>サイト</div>
                <input
                  type="text"
                  name="author_url"
                  className={clsx("input-primary", styles.input)}
                  ref={register}
                />
              </label>
              <input
                type="hidden"
                name="comment_post_ID"
                value={post.databaseId}
                id="comment_post_ID"
                ref={register}
              />
              <input
                type="hidden"
                name="comment_parent"
                value={0}
                id="comment_parent"
                ref={register}
              />
              <Button
                variant="confirm"
                type="submit"
                className={styles.formButton}
              >
                コメントを送信
              </Button>
              <p className={styles.formText}>
                日本語が含まれない投稿は無視されますのでご注意ください。
              </p>
            </form>
          </section>

          <div className={styles.shareCards}>
            <TwitterShareButton url={location.href}>
              <div className={styles.twitter}>
                <IconTwitter />
              </div>
            </TwitterShareButton>
            <FacebookShareButton url={location.href}>
              <div className={styles.facebook}>
                <IconFacebook />
              </div>
            </FacebookShareButton>
            <HatenaShareButton url={location.href}>
              <div className={styles.hatena}>
                <IconHatena />
              </div>
            </HatenaShareButton>
            <LineShareButton url={location.href}>
              <div className={styles.line}>
                <IconLine />
              </div>
            </LineShareButton>
          </div>

          <div className={styles.pager}>
            <ul className={styles.pager__list}>
              <li className={styles.pager__itemPrev}>
                {previous && (
                  <Link
                    to={`/blog${previous.uri}`}
                    rel="prev"
                    className={styles.pager__link}
                  >
                    <IconArrowLeft />
                    {parse(previous.title)}
                  </Link>
                )}
              </li>
              <li className={styles.pager__itemNext}>
                {next && (
                  <Link
                    to={`/blog${next.uri}`}
                    rel="next"
                    className={styles.pager__link}
                  >
                    {parse(next.title)}
                    <IconArrowRight />
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </main>

        <div className={styles.sidebar}>
          <a
            href="https://www.green-japan.com/company/7196"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="./images/recruit.png"
              alt="求ム！人財！意欲ある方、お問い合わせください！募集要項はコチラ"
              width={336}
              quality={100}
            />
          </a>
          <div className={styles.widget}>
            <h2 className={styles.widget__title}>人気の記事</h2>
            <div className={styles.widget__box}>
              <div className={styles.widget__list}>
                {popularPost.map(entry => (
                  <Link
                    key={entry.uri}
                    to={`/blog${entry.uri}`}
                    className={styles.card}
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    {entry.featuredImage?.node?.localFile?.childImageSharp ? (
                      <Img
                        fluid={
                          entry.featuredImage?.node?.localFile?.childImageSharp
                            ?.fluid
                        }
                        className={styles.card__img}
                      />
                    ) : (
                      <StaticImage
                        src="../images/common/noimg.png"
                        alt=""
                        width={150}
                        height={84}
                        className={styles.card__img}
                      />
                    )}
                    <p className={styles.card__title}>{entry.title}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.shareBox}>
            <TwitterShareButton url={location.href}>
              <TwitterIcon size={55} round className={styles.boxIcon} />
            </TwitterShareButton>
            <FacebookShareButton url={location.href}>
              <FacebookIcon size={55} round className={styles.boxIcon} />
            </FacebookShareButton>
            <HatenaShareButton url={location.href}>
              <HatenaIcon size={55} round className={styles.boxIcon} />
            </HatenaShareButton>
            <LineShareButton url={location.href}>
              <LineIcon size={55} round className={styles.boxIcon} />
            </LineShareButton>
          </div>
        </div>
      </div>
    </BlogLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # 人気の記事データ
    allPageViews(limit: 8, sort: { order: DESC, fields: totalCount }) {
      nodes {
        totalCount
        path
      }
    }

    # 全記事データ
    allWpPost {
      nodes {
        uri
        date(formatString: "YYYY.MM.DD")
        title
        excerpt
        categories {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 168) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    # 個別記事データ
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      databaseId
      date(formatString: "YYYY.MM.DD")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      commentCount
      comments {
        nodes {
          id
          date(formatString: "YYYY.MM.DD HH:mm")
          content
          parentId
          author {
            node {
              name
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
